import React, { useContext, useEffect, useState } from 'react';
import { classList} from '../Helpers/Functions';
import { withTranslation } from 'react-i18next';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import BuildingMenuItem from './BuildingMenuItem';
import UtilsContext from '../Context/utilsContext';

function Sidebar({ t }) {
    const [sidebarState, setSidebarState] = useState({ buildings: [], isOpen: false });
    const [sortedBuildings, setSortedBuildings] = useState({ buildings: [], isOpen: false });
    const [sortType, setSortType] = useState("name");
    const [ dropdownStatus, setDropdownStatus ] = useState(false)
    const [sortIconAlphaAscending, setSortIconAlphaAscending] = useState(true);
    const [sortIconNumAscending, setSortIconNumAscending] = useState(undefined);
    const { customers, alerts } = useContext(UtilsContext);

    const nameAsc = dynamicNameSort(1);
    const nameDesc = dynamicNameSort(-1);
    const countAsc = dynamicNameSort(1, 'count');
    const countDesc = dynamicNameSort(-1, 'count');

    const sortFuncs = {
        "name": nameAsc,
        "-name": nameDesc,
        "count": countAsc,
        "-count": countDesc,
    };

    function toggleSidebarOpen() {
        setSidebarState({ ...sidebarState, isOpen: !sidebarState.isOpen });
    }

    function setSortIconAlpha() {
        let nextSortType = sortType === "name" ? "-name" : "name";
        setSortIconAlphaAscending(!sortIconAlphaAscending)
        setSortIconNumAscending(undefined)
        setSortType(nextSortType);
    }

    function setSortIconNum() {
        let nextSortType = sortType === "count" ? "-count" : "count";
        setSortIconNumAscending(!sortIconNumAscending)
        setSortIconAlphaAscending(undefined)
        setSortType(nextSortType);
    }

    function getAlertsCountByBuildingId(buildingId) {
        if (alerts && alerts.buildings) {
            var buildingAlerts = alerts.buildings.find(function (alert) {
                return alert.building === buildingId;
            });
            if (buildingAlerts) {
                return buildingAlerts.alerts_count || 0;
            }
        }
        return 0;
    }

    useEffect(() => {
        if (Object.keys(customers).length > 0) {
            const updatedBuildings = customers.buildings
                .map(building => ({
                    ...building,
                    isOpen: false,
                    zones: building.zones.map(zone => ({ ...zone, isOpen: false })).sort(sortFuncs["name"]),
                    count: getAlertsCountByBuildingId(building.id)
                }))
                .sort(sortFuncs["name"]);

            setSortedBuildings(prevState => ({
                ...prevState,
                buildings: updatedBuildings
            }));
        }
    }, [customers, alerts]);


    function dynamicNameSort(order, property = 'name') {
        return (a, b) => {
            const valueA = a[property];
            const valueB = b[property];

            if (valueA == null && valueB != null) {
                return -1 * order;
            } else if (valueA != null && valueB == null) {
                return 1 * order;
            } else if (valueA != null && valueB != null) {
                return typeof valueA === 'string'
                    ? valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: 'base' }) * order
                    : (valueA - valueB) * order;
            }

            return 0;
        };
    }

    useEffect(() => {
        let dictCopy = {...sortedBuildings};

        const sortFunc = sortFuncs[sortType];
        dictCopy.buildings.sort(sortFunc);

        dictCopy.buildings.forEach((building) => {
            if (building.zones) {
                building.zones.sort(sortFunc);
            }
        });
        setSortedBuildings(dictCopy);
    }, [sortType]);

    return (
        <div
            className={sidebarState.isOpen ? 'd-flex flex-column sidebar sidebar--open': 'd-flex flex-column sidebar'}
        >
            <button className='sidebar__close-btn' onClick={toggleSidebarOpen}>
                <FontAwesomeIcon
                    icon={faBars}
                />
            </button>

            <div className="dropdown-sort dropdown-sort-sidebar">
                    <button onClick={() => setDropdownStatus(!dropdownStatus)} className="dropbtn-sort">
                        <div className="dropdown-building-button-sort">
                        {t(`sort.title`)}
                        <i className="ico ico--sort" />
                        </div>
                    </button>
                    <div className={classList({
                        'dropdown-content-sort': true,
                        'show': dropdownStatus
                    })}>
                        <div onClick={() => setSortIconAlpha()} className="dropdown-row-sort">{t('sort.name')} <i className={classList({
                            "ico ico--sort-solid": (sortIconAlphaAscending === undefined),
                            "ico ico--sort-ascending": (sortIconAlphaAscending !== undefined && sortIconAlphaAscending),
                            "ico ico--sort-descending": (sortIconAlphaAscending !== undefined && !sortIconAlphaAscending)
                        })} /></div>
                        <div onClick={() => setSortIconNum()} className="dropdown-row-sort">{t('sort.alerts')} <i className={classList({
                            "ico ico--sort-solid": (sortIconNumAscending === undefined),
                            "ico ico--sort-ascending": (sortIconNumAscending !== undefined && sortIconNumAscending),
                            "ico ico--sort-descending": (sortIconNumAscending !== undefined && !sortIconNumAscending)
                        })} /></div>
                    </div>
                </div>

            <div className='sidebar-nav'>
                {
                    (alerts !== undefined && alerts['buildings'] !== undefined) ?
                        (
                            sortedBuildings.buildings.map(building_dict => {
                                return (
                                    <BuildingMenuItem key={building_dict.id} building={building_dict} count={building_dict.count}/>
                                )
                            })
                        ) : false
                }
            </div>
        </div>
    )
};

export default withTranslation()(withRouter(Sidebar));
